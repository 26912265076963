//TODO : Put all the details arguments coming from the various application in one constant folder
export const trackUserEventService = (eventType, details) => {
  // IF NO ANONYMOUS USER ID GENERATE ONE, SAVE IN COOKIES AND THEN IN LOCAL STORAGE THEN PROCEED
  const anonymousUserId = localStorage.getItem("anonymous-id");
  const userEvent = JSON.stringify({ anonymousUserId, eventType, details });

  const beaconSent = navigator.sendBeacon(
    "/api/userevent/trackUserEvent",
    userEvent,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (beaconSent) {
    console.log("User event beacon sent successfully");
  } else {
    console.error("Failed to send user event beacon");
  }
};

export const updateAnonymousUserService = (update) => {
  // IF NO ANONYMOUS USER ID GENERATE ONE, SAVE IN COOKIES AND THEN IN LOCAL STORAGE THEN PROCEED
  const anonymousUserId = localStorage.getItem("anonymous-id");

  const beaconSent = navigator.sendBeacon(
    "/api/userevent/updateAnonymousUser",
    JSON.stringify({ anonymousUserId, update }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (beaconSent) {
    console.log("Update Anonymous beacon sent successfully");
  } else {
    console.error("Failed to send user event beacon");
  }
};

export const trackUserEventAndUpdateAnonymousUserService = (
  eventType,
  details,
  update
) => {
  const anonymousUserId = localStorage.getItem("anonymous-id");
  const userEvent = JSON.stringify({ anonymousUserId, eventType, details });

  console.log(userEvent);

  const beaconSent1 = navigator.sendBeacon(
    "/api/userevent/trackUserEvent",
    userEvent,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (beaconSent1) {
    console.log("User event beacon sent successfully");
  } else {
    console.error("Failed to send user event beacon");
  }

  const beaconSent2 = navigator.sendBeacon(
    "/api/userevent/updateAnonymousUser",
    JSON.stringify({ anonymousUserId, update }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (beaconSent2) {
    console.log("Update Anonymous beacon sent successfully");
  } else {
    console.error("Failed to send user event beacon");
  }
};

export const sendSignupEmail = async (email) => {
  const recipientMail = JSON.stringify({ email });
  console.log(recipientMail);
  const beaconSent = navigator.sendBeacon(
    "/api/profile/sendsignupemail",
    recipientMail,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  if (beaconSent) {
    console.log("Mail sent successfully on using beacon request after signup");
  } else {
    console.log("Mail sent failed on using beacon request after signup");
  }
};

// export const updateZappierRecordService = (id,status) => {
// const beaconSent = navigator.sendBeacon(
//     "/api/userevent/updateZappierUser",
//     JSON.stringify({ id, status }),
//     {
//       headers: { "Content-Type": "application/json" },
//     }
//   );

//   if (beaconSent) {
//     console.log("Update Zappier User sent successfully");
//   } else {
//     console.error("Failed to send user event beacon");
//   }
// };
