import "./customizationPopup.scss";
import { useState, useEffect } from "react";
import { ReactComponent as MaeBadgeLogoBright } from "../../assets/logos/mae-badge-logo-bright.svg";
import { ReactComponent as Close } from "../../assets/images/X.svg";
import FormField from "../formField/FormField.jsx";
import ButtonGradient from "../buttonGradient/ButtonGradient.jsx";
import { ReactComponent as ErrorSVG } from "../../assets/images/error.svg";
import { trackAndUpdateComparisonChartInfo } from "../../services/comparisonchart.js";
import { useAuthContext } from "../../contexts/authContext.jsx";
import { OFFER_BANNER_FORM_SUBMISSION_EVENT_TYPE } from "../../constants/userEvents.js";

function FormFieldWithLabel({
  label,
  type,
  id,
  placeholder,
  value,
  onChange,
  errorMessage,
  rows
}) {
  return (
    <>
      <div
        className={`form_field_with_label ${
          id === "help-box" ? "w-form-100" : ""
        }`}
      >
        <div className="form_field_with_label__container">
          <span className="form_field_with_label__text">{label}</span>
          <div className="error_message_container">
            {errorMessage ? <ErrorSVG /> : null}
            <span className="error_message_container__text">
              {errorMessage}
            </span>
          </div>
        </div>
        <FormField
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          rows={rows}
        />
      </div>
    </>
  );
}

export default function CustomizationPopup({ togglePopup, submittedPopup, dealershipName, offerText }) {
  const { lastChartId } = useAuthContext();

  const [name, setName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [timelineError, setTimelineError] = useState("");
  const [helpBoxText, setHelpBoxText] = useState("");
  const [timelineOptions, setTimeLineOptions] = useState([
    "1-2 months",
    "3-6 months",
    "6-12 months",
    "12 months+",
  ]);
  const [timeline, setTimeline] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validatePostalCode = (postalCode) => {
    const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    if (!postalCodeRegex.test(postalCode)) {
      setPostalCodeError("Invalid postal code");
      return false;
    }
    setPostalCodeError("");
    return true;
  };

  const validatePhoneNo = (phoneNo) => {
    const phoneNoRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNoRegex.test(phoneNo)) {
      setPhoneNoError("Invalid phone number");
      return false;
    }
    setPhoneNoError("");
    return true;
  };

  const validateTimeline = (timeline) => {
    const errorNotPresent = timelineOptions.includes(timeline) ? true : false;

    if (!errorNotPresent) {
      setTimelineError("Empty Field");
    } else {
      setTimelineError("");
    }

    return errorNotPresent;
  };

  return (
    <>
      <div className="customization_popup_container">
        <div className="badge_logo_bright">
          <MaeBadgeLogoBright />
        </div>
        <div
          className="close_popup_container"
          onClick={() => {
            togglePopup(false);
          }}
        >
          <Close />
        </div>
        <div className="popup_content">
          <div className="popup_header">
            <p className="popup_header--maintext">Exclusive Mae Offers</p>
            <p className="popup_header--subtext">
               <div> {offerText ? `${offerText} ` : `We hand-select deals and offers that match your preferences and
              are worthy of your time! `}
              <a
                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3jPtF8-1ofTxq2J5BykFwPtfy7_F5H4-d8Nz8ZnjUWvO-nzG-1OOFlZhWrObZOglitvs2pWJn7"
                className="booking_link"
              >
                Book 1:1 with Vivian here!
              </a></div>
              
            </p>
          </div>
          {/* <div className="combine-fields">
            <FormFieldWithLabel
              label="Name"
              type="text"
              id="name"
              placeholder=""
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              errorMessage={""}
            />
            <div className="form_field_with_label">
              <div className="form_field_with_label__container">
                <span className="form_field_with_label__text">Timeline</span>
                <div className="error_message_container">
                  {timelineError ? <ErrorSVG /> : null}
                  <span className="error_message_container__text">
                    {timelineError}
                  </span>
                </div>
              </div>
              <select
                value={timeline}
                onChange={(e) => {
                  setTimeline(e.target.value);
                }}
                className="dropdown-timeline"
              >
                <option value="">Select Timeline</option>
                {timelineOptions.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div> */}
          <div className="combine-fields">
            <FormFieldWithLabel
              label="Postal Code"
              type="text"
              id="postal-code"
              placeholder=""
              value={postalCode}
              onChange={(e) => {
                setPostalCode(e.target.value);
              }}
              errorMessage={postalCodeError}
            />
            <FormFieldWithLabel
              label="Phone No."
              type="text"
              id="phone-no"
              placeholder=""
              value={phoneNo}
              onChange={(e) => {
                setPhoneNo(e.target.value);
              }}
              errorMessage={phoneNoError}
            />
          </div>
          {/* <FormFieldWithLabel
            label="How can we help?"
            type="text-area"
            id="help-box"
            placeholder=""
            value={helpBoxText}
            onChange={(e) => {
              setHelpBoxText(e.target.value);
            }}
            rows={5}
          /> */}
          <div className="button_container">
            <ButtonGradient
              text="Claim"
              onClick={() => {
                let postalcodeError = validatePostalCode(postalCode);
                let phonenoError = validatePhoneNo(phoneNo);
                // let timelineError = validateTimeline(timeline);
                if (postalcodeError && phonenoError) {
                  togglePopup(false);
                  submittedPopup(true);
                  trackAndUpdateComparisonChartInfo({
                    interactionType: OFFER_BANNER_FORM_SUBMISSION_EVENT_TYPE,
                    chartId: lastChartId,
                    additionalDetails: {
                      // name: name,
                      postalCode: postalCode,
                      phoneNo: phoneNo,
                      // timeline: timeline,
                      // helpBox: helpBoxText,
                      dealershipName: dealershipName
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
