
export const updateAccountInfo = async (infoToBeUpdated) => {
try {
    console.log(infoToBeUpdated,"infoToBeUpdated")
    const response = await fetch("/api/account/update-account-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      credentials: "include",
      body: JSON.stringify(infoToBeUpdated),
    });
    if (response.ok) {
      const res = await response.json();
      console.log(res);
    } else {
      const errorData = await response.json();
      console.log(errorData.error);
    }
  } catch (error) {
    console.log("Error:", error.message);
}
}