import "./App.css";
import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "./contexts/authContext";
import Main from "./pages/Main/Main";
import NotFound from "./pages/NotFound/NotFound";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Quiz from "./pages/Quiz/Quiz";
import SpeedDatingQuiz from "./pages/SpeedDatingQuiz/SpeedDatingQuiz";
import SpeedDating from "./pages/SpeedDating/SpeedDating";
import MyProfile from "./pages/MyProfile/Profile";
import Gallery from "./pages/Gallery/Gallery";
import SpeedDatingLoading from "./pages/SpeedDatingLoading/SpeedDatingLoading";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MDP from "./pages/MDP/MDP";
import LoadingScreen from "./components/loadingScreen/loadingScreen";
import ComparisonChart from "./components/comparisonChart/comparisonChart";
import AddCarsForComparison from "./components/addCarsForComparison/addCarsForComparison";
import ProtectedRoute from "./utils/protectedRoutes";
import {
  NAVIGATED_TO_MATCHES,
  NAVIGATED_TO_SPEED_DATING,
  PROFILE_GENERATED,
  PROFILE_UPDATED,
} from "./constants/lastActions";
import GoogleNavigation from "./pages/GoogleNavigation/GoogleNavigation";

function App() {
  const {
    isLoggedIn,
    matchmaker,
    lastAction,
    user,
    isAuthContextLoading,
    setLastAction,
    printMatchmaker,
    userSource
  } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(
      "Use effect, App Check",
      isAuthContextLoading,
      isLoggedIn,
      matchmaker,
      user,
      lastAction,
      userSource
    );
    if (!isAuthContextLoading) {
      printMatchmaker();
      // if (
      //   matchmaker?.profileQuiz?.length === 0 &&
      //   matchmaker?.profileQuizMaeTrims?.length === 0
      // ) {
      //   console.log("Redirect to matchmaker quiz.");
      //   navigate("/matchmaker-quiz");
      // } else if(lastAction === PROFILE_GENERATED){
      //   navigate("/compare");
      // }
    }
   
  
  }, [matchmaker, user, isAuthContextLoading]);

  if (isAuthContextLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/speed-dating/quiz" element={<SpeedDatingQuiz />} />
          <Route path="/matches" element={<Gallery />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/maetrims/:maeTrimId" element={<MDP />} />
          <Route path="/compare" element={<ComparisonChart />} />
          <Route path="/add-cars" element={<AddCarsForComparison />} />
        </Route>

        <Route path="/" element={<Main />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/google-navigation-upon-logging-in" element={<GoogleNavigation/>} />
        <Route path="/matchmaker-quiz" element={<Quiz />} />
        <Route path="/speed-dating" element={<SpeedDating />} />
        <Route path="/loading" element={<SpeedDatingLoading />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword show="email" />}
        />
        <Route
          path="/password-reset/:userId/:token"
          element={<ForgotPassword show="reset" />}
        />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
