import "../../styles/formStyles/formStyles.scss";
import React, { useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import SigninForm from "../../components/signinForm/SigninForm";
import DashboardAuthenticate from "../../components/dashboardAuthenticate/DashboardAuthenticate";
import { useNavigate } from "react-router-dom";
import { updateComparisonChartInfo } from "../../services/comparisonchart";

const SignIn = () => {
  const { login, setTriggerUpdateAuthContext, matchmaker } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInStatus, setSignInStatus] = useState("");
  const [accountSignInError, setAccountSignInError] = useState("");
  const navigate = useNavigate();

  const googleAuth = () => {
    console.log(process.env.REACT_APP_GOOGLE_AUTH_API_URL);
    window.open(process.env.REACT_APP_GOOGLE_AUTH_API_URL, "_self");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    if (!email || !password) {
      setAccountSignInError("empty");
      return;
    }

    const btnSpinner = document.getElementById("btn-spin-loader");
    btnSpinner.className = "show-spin-loader";

    try {
      const response = await fetch("/api/auth/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        setSignInStatus("Login successful");
        console.log("Login successful");
        login();
        setTriggerUpdateAuthContext((prevCount) => prevCount + 1);
        
        const responseJSON = await response.json()
        if (responseJSON.user.profile?.profileQuizMaeTrimIds || matchmaker?.profileQuiz?.length !== 0 &&
          matchmaker?.profileQuizMaeTrims?.length !== 0) {
            navigate("/compare")
          } else {
          navigate("/matchmaker-quiz");
        }
      } else {
        const errorData = await response.json();
        setSignInStatus(errorData.error);
        console.log("Login failed:", errorData.error);
        if (errorData.error === "Password does not match!") {
          setAccountSignInError("password");
        }
        if (errorData.error === "No User Found!") {
          setAccountSignInError("user");
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
    btnSpinner.className = "hide-spin-loader";
  };

  return (
    <div className="signup-login-page">
      <SigninForm
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        signInStatus={signInStatus}
        handleSubmit={handleSubmit}
        accountSignInError={accountSignInError}
        googleAuth={googleAuth}
      />
      <DashboardAuthenticate />
    </div>
  );
};

export default SignIn;
