/* eslint-disable react/jsx-no-comment-textnodes */
import "./comparisonChart.scss";
import { useEffect, useState, React } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext.jsx";
import CustomizationPopup from "../customizationPopup/customizationPopup.jsx";
import FeaturePopup from "../featurePopup/featurePopup.jsx";
import UpdatePopup from "../updatePopup/updatePopup.jsx";
import FeatureRequirementIcon from "../FeatureRequirementIcon/FeatureRequirementIcon.jsx";
import { formatToOneDecimal } from "../../utils/formatUtils.jsx";
import { ReactComponent as BackIcon } from "../../assets/images/back-arrow.svg";
import { ReactComponent as AddCarSign } from "../../assets/icons/add-cars.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as InfoYellow } from "../../assets/icons/info-yellow.svg";
import { ReactComponent as Bin } from "../../assets/icons/bin.svg";
import { ReactComponent as DividerLine } from "../../assets/icons/divider.svg";
import { ReactComponent as MaeBadgeLogoBright } from "../../assets/logos/mae-badge-logo-bright.svg";
import {
  updateComparisonChartInfo,
  trackAndUpdateComparisonChartInfo,
} from "../../services/comparisonchart.js";
import { ReactComponent as AddFeaturesButton } from "../../assets/icons/add-features.svg";
import { ANONYMOUS_PROFILE_GENERATED, MATCHMAKER_UPDATED } from "../../constants/lastActions.js";
import { getCarsInfoFromId } from "../../services/mdp.js";
import LoadingScreen from "../loadingScreen/loadingScreen.jsx";
import { BUTTON_CLICK_EVENT_TYPE, COMPARISON_CHART_VIEW_TIME_EVENT_TYPE } from "../../constants/userEvents.js";
import { trackUserEventService } from "../../services/userEvent.js";
import { updateAccountInfo } from "../../services/account.js";
import ComparisonChartCompatibilitySection from "../comparisonChartCompatibilitySection/comparisonChartCompatibilitySection.jsx";
import Banner from "../banner/banner.jsx";
import { DEALERSHIP_CONFIG } from "../../config/dealershipConfig.js";
import dashboardLogo from "../../assets/logos/Mae-Core-Logo-Yellow.png";
import { ReactComponent as Close } from "../../assets/images/X.svg";
import helpVideo from "../../assets/videos/helpPopUp.mp4";

function LabelWithTooltip({ classname, label, tooltipInfo, type = "label" }) {
  function showTooltip(tooltipClass) {
    const tooltip = document.querySelector(tooltipClass);
    tooltip.style.visibility = "visible";
    tooltip.style.opacity = "1";
  }

  function hideTooltip(tooltipClass) {
    const tooltip = document.querySelector(tooltipClass);
    tooltip.style.visibility = "hidden";
    tooltip.style.opacity = "0";
  }

  return (
    <div className="label-with-info">
      <p
        className={`${type === "label" ? "label-text" : "chart_value_text"}`}
        style={{ display: "block" }}
        onMouseEnter={() => showTooltip(`.${classname}`)}
        onMouseLeave={() => hideTooltip(`.${classname}`)}
      >
        {`${label} `}
        <Info className="translate-y_50" />
      </p>

      <div className={`tooltip ${classname}`}>{tooltipInfo}</div>
    </div>
  );
}

function DividerForCarsComparison({ carsToCompareList, opacity }) {
  if (opacity) {
    return (
      <div className="chart-container" style={{ height: "5px" }}>
        <DividerLine className="invisible" />
      </div>
    );
  }
  return (
    <div className="chart-container" style={{ height: "5px" }}>
      {carsToCompareList &&
        carsToCompareList?.length > 0 &&
        carsToCompareList.map((el, index) => {
          const className1 = index === 0 ? "" : "minus-left-margin-divider";
          return <DividerLine key={index} className={className1} />;
        })}
    </div>
  );
}

export default function ComparisonChart() {
  const {
    matchmaker,
    currentComparisonChartState,
    setCurrentComparisonChartState,
    lastChartId,
    setLastChartId,
    updateMatchmaker,
    lastAction,
    extraVehicles,
    setExtraVehicles,
    userAddedFeatures,
    setUserAddedFeatures,
    isAuthContextLoading,
    userSource,
    comparisonChartViewed,
    setComparisonChartViewed,
  } = useAuthContext();
  const navigate = useNavigate();

  console.log("user source in comparison chart",userSource)

  const DEALERSHIP_SOURCE = userSource in DEALERSHIP_CONFIG;
  const DEALERSHIP_LOGO = DEALERSHIP_CONFIG[userSource]?.logo
  const DEALERSHIP_NAME = DEALERSHIP_CONFIG[userSource]?.name
  const DEALERSHIP_OFFER_TEXT = DEALERSHIP_CONFIG[userSource]?.offerText
  const DEALERSHIP_OFFER_BUTTON_TEXT = DEALERSHIP_CONFIG[userSource]?.offerButtonText

  const [carsToCompareList, setCarsToCompareList] = useState([]);
  const [exclusiveCtaPopup, setExclusiveCtaPopup] = useState(false);
  const [FeatureCtaPopup, setFeatureCtaPopup] = useState(false);
  const [customizationPopupToggle, setCustomizationPopupToggle] = useState(false);
  const [featurePopupToggle, setFeaturePopupToggle] = useState(false);
  const [mobileViewHiddenToggle, setMobileViewHiddenToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageViewStartTime, setPageViewStartTime] = useState(Date.now());
  let isUnmounting = false;
  const [isHelpPopupVisible, setIsHelpPopupVisible] = useState(!comparisonChartViewed); 
  
  const toggleHelpPopup = (isVisible) => {
  setIsHelpPopupVisible(isVisible);
  };

  const submittedPopupText =
    "Thanks! A car buying bestie from Mae will be in touch! ";

  const mobileViewNotReady = `This Feature is only available for Desktop screens. To enable
  this feature in your mobile screens and tablet kindly view it by enabling desktop site`;

  const addedFeatureText = "Your Selected features are added below!";

  useEffect(() => {
    console.log("LAST ACTION:", lastAction);
    if (lastAction !== MATCHMAKER_UPDATED && lastAction !== ANONYMOUS_PROFILE_GENERATED) updateMatchmaker();
  }, []);

  const onBackButton = () => {
    navigate("/matches");
  };

  const getCarDetails = async (carsToCompareIds) => {
    if(matchmaker?.profileQuizMaeTrims.length > 0) {
      return await getCarsInfoFromId(
        carsToCompareIds,
        matchmaker?.profileQuizMaeTrims[0].totalFeatures
      );
    }
  };

  console.log(comparisonChartViewed);

  useEffect(() => {
    const fetchCarDetails = async () => {
      window.scrollTo(0, 0);
      console.log(
        currentComparisonChartState,
        "currentComparisonChartsState",
        lastChartId,
        "lastChartId"
      );

      try {
        console.log(lastChartId, currentComparisonChartState, currentComparisonChartState);
        const res = await getCarDetails(currentComparisonChartState);
        console.log(res, "res");
        if (res.carsInfo) {
          const carsToCompare = res.carsInfo;
          setCarsToCompareList(carsToCompare);
        } else {
          console.log("To Matches");
          navigate("/matches");
        }
      } catch (error) {
        console.error("Error fetching car details:", error);
        // Handle the error appropriately here
      } finally {
        setLoading(false);
      }
    };

    if (!isAuthContextLoading){
      fetchCarDetails();
    }
    
  }, [isAuthContextLoading]);


  useEffect(() => {
    const width = window.innerWidth;

    if (width <= 1024) {
      setMobileViewHiddenToggle(true);
    }
  }, []);


  function closePopup() {
    toggleHelpPopup(false);
    async function callToUpdateAccountInfo() {
      await updateAccountInfo({comparisonChartViewed : true});
      setComparisonChartViewed(true);
    }
    if(!comparisonChartViewed) callToUpdateAccountInfo();
  }

  useEffect(() => {
    if (isAuthContextLoading){
      return;
    }
    if(userAddedFeatures.length > 0 || matchmaker.profileQuizMaeTrims.length <= 0) return;
    Object.keys(
      matchmaker.profileQuizMaeTrims[0].vehicleInfo.maeFeatures
    ).forEach((feature) => {
      if (
        userAddedFeatures.length < 5 &&
        !matchmaker?.profileQuizMaeTrims[0].totalFeatures.includes(feature) &&
        feature !== "_id"
      ) {
        userAddedFeatures.push(feature);
      }
    });
    setUserAddedFeatures(userAddedFeatures);
  }, [userAddedFeatures]);

  useEffect(() => {
    return () => {
      if(isUnmounting){
        const pageViewEndTime = Date.now();
        const timeSpentInSeconds = (pageViewEndTime - pageViewStartTime) / 1000;
        trackUserEventService(COMPARISON_CHART_VIEW_TIME_EVENT_TYPE, {
          timeSpent: timeSpentInSeconds,
        });
      }

      isUnmounting = true;
    };
  },[]);

  function adjustHeight(eptypeDivs,class_name) {
    const labelDiv = document.querySelector(class_name);
    let maxHeight = 0;

    eptypeDivs.forEach((div) => {
      // console.log(div.offsetHeight,div,"div");
      const height = div.offsetHeight;
      maxHeight = Math.max(maxHeight, height);
    });

    eptypeDivs.forEach((div) => {
      div.style.height = `${maxHeight}px`;
    });

    labelDiv.style.height = `${maxHeight}px`;
}

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const eptypeDivs = document.querySelectorAll(".eptypevalue");
      const carNameDivs = document.querySelectorAll(".carName");
      const recommendedTrimDivs = document.querySelectorAll(".recommended_value");
      const compatibilityDivs = document.querySelectorAll(".compatibility_value");
      const moreFeatureDivs = document.querySelectorAll(".more-features");
      if (eptypeDivs.length > 0) {
        adjustHeight(eptypeDivs,".eptype");
      }
      if (carNameDivs.length > 0) {
        adjustHeight(carNameDivs,".carNameDiv");
      }
      if (recommendedTrimDivs.length > 0) {
        adjustHeight(recommendedTrimDivs,".recommended_label");
      }
      if (compatibilityDivs.length > 0) {
        adjustHeight(compatibilityDivs,".compatibility_label");
      }
      if (moreFeatureDivs.length > 0) {
        adjustHeight(moreFeatureDivs,".more_features_connected_label");
      }
  });
    
    const config = { childList: true, subtree: true };
    const targetNode = document.getElementById("root");

    if (targetNode) {
      observer.observe(targetNode, config);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (exclusiveCtaPopup || FeatureCtaPopup || isHelpPopupVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [exclusiveCtaPopup, FeatureCtaPopup, isHelpPopupVisible]);

  async function callToUpdateCCState(remainingCarIds,remainingExtraCars) {
    const res = await updateComparisonChartInfo(remainingCarIds,remainingExtraCars);
    if (res.comparisonChartUserState) {
      setLastChartId(res.comparisonChartUserState._id);
    }
  }

  function trackBannerButton() {
    trackAndUpdateComparisonChartInfo({
      chartId: lastChartId,
      interactionType: BUTTON_CLICK_EVENT_TYPE,
      additionalDetails: {
        buttonType: DEALERSHIP_SOURCE ? "CLAIM_OFFER" :"BUY_WITH_MAE",
        dealershipName: DEALERSHIP_NAME
      },
    });
  }


  function exclusiveCtaClicked() {
    window.scrollTo(0, 0);
    setExclusiveCtaPopup(true);
    trackBannerButton()
  }

  function featureCtaClicked() {
    window.scrollTo(0, 0);
    setFeatureCtaPopup(true);
  }

  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  function formatCapitalisation(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  }

  async function removeCar(id) {
    let carList = [...carsToCompareList];
    const remainingCarIds = [];
    console.log(id,"id");
    carList = carList.filter((car, i) => {
      if (car._maeTrimId !== id) remainingCarIds.push(car._maeTrimId);
      return car._maeTrimId !== id;
    });
    const remainingExtraCars = extraVehicles.filter((car) => {
      console.log(car.vehicleInfo._maeTrimId);
      return car.vehicleInfo._maeTrimId !== id;
    });
    setCarsToCompareList(carList);
    setCurrentComparisonChartState(remainingCarIds);
    setExtraVehicles(remainingExtraCars);
    await callToUpdateCCState(remainingCarIds,remainingExtraCars);
    await trackAndUpdateComparisonChartInfo({
      chartId: lastChartId,
      interactionType: "delete",
      additionalDetails: {
        carId: id,
      },
    });
  }

  if (loading && !isAuthContextLoading) {
    return <LoadingScreen />;
  }

  return (
<>
      {exclusiveCtaPopup ? (
        <CustomizationPopup
          togglePopup={setExclusiveCtaPopup}
          submittedPopup={setCustomizationPopupToggle}
          dealershipName={DEALERSHIP_NAME}
          offerText={DEALERSHIP_OFFER_TEXT}
        />
      ) : null}
      {FeatureCtaPopup ? (
        <FeaturePopup
          toggleHelpPopup={setFeatureCtaPopup}
          submittedPopup={setFeaturePopupToggle}
          userAddedFeatures={userAddedFeatures}
          setUserAddedFeatures={setUserAddedFeatures}
        />
      ) : null}

      {customizationPopupToggle ? (
        <div className="popup_position">
          <UpdatePopup
            Popupshow={setCustomizationPopupToggle}
            main={submittedPopupText}
          />
        </div>
      ) : null}

      {featurePopupToggle ? (
        <div className="popup_position">
          <UpdatePopup
            Popupshow={setFeaturePopupToggle}
            main={addedFeatureText}
          />
        </div>
      ) : null}

      {mobileViewHiddenToggle ? (
        <div className="popup_position">
          <UpdatePopup
            Popupshow={setMobileViewHiddenToggle}
            main={mobileViewNotReady}
          />
        </div>
      ) : null}

    {(isHelpPopupVisible) && (
      <div className="blur-overlay">
      <div className="customization_popup_container">
        <div className="popup-content">
          <div className="badge_logo_bright">
            <MaeBadgeLogoBright />
          </div>
          <div className="close_popup_container" onClick={closePopup}>
            <Close />
          </div>
          <p className="popup_header--maintext">How to use the Comparison Chart </p>
          <p className="popup_header--subtext"> From the founder of Mae, Vivian</p>
          <div className="video-container">
            <video controls autoPlay loop muted>
              <source src={helpVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      </div>
    )}

      <div
        className={`comparechart ${
          exclusiveCtaPopup || FeatureCtaPopup ? "blured" : ""
        }`}
      >
        {DEALERSHIP_SOURCE? <div className="comparechart__title-container">
        
              <img
              className="dealership-logo"
              src={DEALERSHIP_LOGO}
              alt={DEALERSHIP_NAME}
            /> 

        <div className="back-button-container" onClick={onBackButton}>
            <BackIcon />
            <p className="back-button-container__text">Back</p>
          </div>
         </div> : null} 
       
        {DEALERSHIP_SOURCE? 
        <div style={{display:"flex", alignItems:"center", paddingLeft:"1.5rem", marginTop:"0.5rem", marginBottom:"0.5rem"}}>
          <h3 className="main-title">Comparison Chart</h3>
          <div className="comparechart__poweredBy" style={{marginLeft:"1rem"}}>
             <div className="comparechart__poweredBy--text" style={{fontWeight : "400", marginRight:"1rem"}}>powered by</div>
             <img
               className="comparechart__poweredBy--logo"
               src={dashboardLogo}/>
            </div>
        </div> :
        <div className="comparechart__title-container">
          <h3 className="main-title">Comparison Chart</h3>

          <div className="back-button-container" onClick={onBackButton}>
            <BackIcon />
            <p className="back-button-container__text">Back</p>
          </div>
        </div>} 

        <Banner onClick={exclusiveCtaClicked} 
        text={DEALERSHIP_SOURCE ? DEALERSHIP_OFFER_TEXT : "Get Exclusive deals and offers on these vehicles from Mae's hand-picked and trusted dealers near you."}
        buttonText={DEALERSHIP_SOURCE ? DEALERSHIP_OFFER_BUTTON_TEXT :"Buy with mae"}
        heading={false}/>
        <div className="help-link-container">
          <a
            className="help-link"
            onClick={(e) => {
              e.preventDefault(); 
              toggleHelpPopup(true); 
            }}
          >
            <InfoYellow style={{height:"1rem", marginRight:"0.5rem"}}/> How to use the comparison chart
          </a>
        </div>
        <div className="chart-layout">
          <div className="compare-label-layout">
            <h3 className="chart_car_label carNameDiv">Cars</h3>
            <AddCarSign
              className="add_car_sign"
              onClick={() => {
                navigate("/add-cars");
              }}
            />

            <div className="recommended_label">
              <LabelWithTooltip
                classname="tooltip1"
                label="Best Trim"
                tooltipInfo=""
              />
            </div>

            <LabelWithTooltip
              classname="tooltip2"
              label="Base MSRP"
              tooltipInfo="This does not include taxes, dealer fees, extra colours,
              shipping costs etc."
            />

            <DividerForCarsComparison
              carsToCompareList={carsToCompareList}
              opacity={true}
            />

            <div className="compatibility_label">
              <LabelWithTooltip
                classname="tooltip3"
                label="Compatibility"
                tooltipInfo="We calculate our compatibility through a combination of vehicle
              information prioritising price and features cause we want to get
              you the most value for least amount of money"
              />
            </div>

            <DividerForCarsComparison
              carsToCompareList={carsToCompareList}
              opacity={true}
            />

            <div className="label-group">
              <p className="label-text-yellow-heading">Requirements</p>
              <p className="label-text eptype">Engine Power Type</p>
              <p className="label-text">Drive Type</p>
            </div>

            <DividerForCarsComparison
              carsToCompareList={carsToCompareList}
              opacity={true}
            />

            <div className="label-group desired-features">
              <p className="label-text-yellow-heading">Your Desired Features</p>
              {carsToCompareList?.length !== 0 &&
                carsToCompareList[0]?.totalFeatures.map((feature, index) => (
                  <p key={index} className="label-text">
                    {formatCapitalisation(feature)}
                  </p>
                ))}
            </div>

            <DividerForCarsComparison
              carsToCompareList={carsToCompareList}
              opacity={true}
            />

            <div className="label-group other-features">
              <p
                className="label-text-yellow-heading more_features_connected_label"
              >
                Other Features
              </p>
              {userAddedFeatures.map((feature, index) => (
                <div key={index}>
                  <p className="label-text">{formatCapitalisation(feature)}</p>
                </div>
              ))}
            </div>

            <DividerForCarsComparison
              carsToCompareList={carsToCompareList}
              opacity={true}
            />

            <div className="label-group lifestyle">
              <p className="label-text-yellow-heading">Lifestyle</p>
              <LabelWithTooltip
                classname="tooltip4"
                label="Big Families"
                tooltipInfo="6+ seats"
              />
              <LabelWithTooltip
                classname="tooltip5"
                label="Driving in Snowy Conditions"
                tooltipInfo="This vehicle has AWD or 4x4 drive type meaning it has power
              running to all four wheels which is recommended for people who
              love their roadtrips to Whistler."
              />
              <LabelWithTooltip
                classname="tooltip6"
                label="Tall Passenger"
                tooltipInfo="This vehicle has head room and leg room greater than 38 inches."
              />
              <p className="label-text">A to B City Commuting</p>
              <p className="label-text">Eco Conscious</p>
              <p className="label-text">Large Trunk Needs</p>
            </div>

            <DividerForCarsComparison
              carsToCompareList={carsToCompareList}
              opacity={true}
            />

            <div className="label-group">
              <p className="label-text-yellow-heading">Dimensions</p>
              <p className="label-text">Trunk Volume</p>
              <p className="label-text">1st Head Room</p>
              <p className="label-text">2nd Head Room</p>
              <p className="label-text">1st Row Leg Room</p>
              <p className="label-text">2nd Row Leg Room</p>
            </div>
          </div>

          <div className="compare-values-layout">
            {/*name*/}
            <div className="chart-container carName">
              {carsToCompareList.map((vehicle, index) => (
                <div className="name-with-bin" key={index}>
                  <h3 className="chart_car_label">
                    {`${vehicle.vehicleInfo.make} ${vehicle.vehicleInfo.model}`}
                  </h3>
                  <Bin onClick={() => removeCar(vehicle._maeTrimId)} />
                </div>
              ))}
            </div>

            {/* image */}
            <div className="chart-container">
              {carsToCompareList.map((vehicle, index) => (
                <div className="img-block" key={index}>
                  <img
                    className="img-car"
                    src={vehicle.vehicleInfo.amazonImage}
                    alt={vehicle.calculatedMDPFeatures.maeTrimTitle}
                  />
                </div>
              ))}
            </div>

            {/* Recommended Trim for the car */}
            <div className="chart-container recommended_value">
              {carsToCompareList.map((vehicle, index) => (
                <div key={index} className="recommended-trim-block">
                  <h4 className="recommended-trim-block--text">
                    {vehicle.vehicleInfo.styleDescription}
                  </h4>
                </div>
              ))}
            </div>

            {/* Price section of the car */}
            <div className="chart-container">
              {carsToCompareList.map((vehicle, index) => (
                <div key={index} className="price-block chart_value_text">
                  <p className="price-block--currency">$</p>
                  {formatPrice(vehicle.vehicleInfo.baseMSRP)}
                </div>
              ))}
            </div>
            <DividerForCarsComparison carsToCompareList={carsToCompareList} />

            {/* Compatibility for the cars */}
            <div className="chart-container compatibility_value">
              {carsToCompareList.map((vehicle, index) => (
                <ComparisonChartCompatibilitySection key={index} vehicle={vehicle} />
              ))}
            </div>

            <DividerForCarsComparison carsToCompareList={carsToCompareList} />

            {/* Requirement for the car */}
            <div className="chart-container">
              {carsToCompareList.map((vehicle, index) => (
                <div key={index} className="label-group">
                  <p
                    className="label-text-yellow-heading"
                    style={{ opacity: "0" }}
                  >
                    Requirements
                  </p>
                  <p
                    className="chart_value_text eptypevalue"
                    style={{ display: "block" }}
                  >
                    {vehicle.calculatedMDPFeatures.engine.map(
                      (powerType, i) => (
                        <span key={i}>
                          {powerType === "PHEV" && "Plug-In Hybrid Gasoline"}
                          {powerType === "HEV" && "Hybrid Gasoline"}
                          {powerType !== "PHEV" &&
                            powerType !== "HEV" &&
                            powerType}{" "}
                          {"Engine"}
                          {i <
                            vehicle.calculatedMDPFeatures.engine?.length - 1 && (
                            <span>, </span>
                          )}
                        </span>
                      )
                    )}
                  </p>
                  <div className="drive-type-container">
                    <LabelWithTooltip
                      classname={`tooltip${index + 7}`}
                      label={vehicle.vehicleInfo.driveType}
                      tooltipInfo={
                        vehicle.vehicleInfo.driveType === "AWD"
                          ? "All Wheel Drive"
                          : vehicle.vehicleInfo.driveType === "FWD"
                            ? "Forward Wheel Drive"
                            : "4 Wheel Drive"
                      }
                      type="value"
                    />
                  </div>
                </div>
              ))}
            </div>

            <DividerForCarsComparison carsToCompareList={carsToCompareList} />

            {/* Your desired features for the car */}
            <div className="chart-container">
              <div className="label-group expanded_mobile_width">
                <div style={{ opacity: "0" }}>
                  <FeatureRequirementIcon requirementPresent={true} />
                </div>
                {carsToCompareList?.length !== 0 &&
                  carsToCompareList[0]?.totalFeatures.map((feature, index) => (
                    <div className="chart-feature-inner-flex" key={index}>
                      {carsToCompareList.map((vehicle, index) => {
                        return vehicle.vehicleInfo.maeFeatures[feature] ===
                          true ? (
                          <FeatureRequirementIcon
                            requirementPresent={true}
                            key={index}
                          />
                        ) : (
                          <FeatureRequirementIcon
                            requirementPresent={false}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  ))}
              </div>
            </div>

            <DividerForCarsComparison carsToCompareList={carsToCompareList} />

            {/* Your other features for the car */}
            <div className="chart-container">
              <div className="label-group expanded_mobile_width">
                <div className="more-features" onClick={featureCtaClicked}>
                  <span className="more-features__add">
                    <AddFeaturesButton />
                  </span>
                  <span className="more-features__text">Add more Features</span>
                </div>
                {userAddedFeatures.map((feature, index) => (
                  <div className="chart-feature-inner-flex" key={index}>
                    {carsToCompareList.map((vehicle) => {
                      return vehicle.vehicleInfo.maeFeatures[feature] ===
                        true ? (
                        <FeatureRequirementIcon
                          requirementPresent={true}
                          key={`${vehicle._maeTrimId}${index}`}
                        />
                      ) : (
                        <FeatureRequirementIcon
                          requirementPresent={false}
                          key={`${vehicle._maeTrimId}${index}`}
                        />
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>

            <DividerForCarsComparison carsToCompareList={carsToCompareList} />

            {/* Lifestyle section of the car */}
            <div className="chart-container">
              <div className="label-group expanded_mobile_width">
                <div style={{ opacity: "0" }}>
                  <FeatureRequirementIcon requirementPresent={true} />
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => {
                    return vehicle.calculatedMDPFeatures.seats > 5 ? (
                      <FeatureRequirementIcon
                        requirementPresent={true}
                        key={vehicle._maeTrimId}
                      />
                    ) : (
                      <FeatureRequirementIcon
                        requirementPresent={false}
                        key={vehicle._maeTrimId}
                      />
                    );
                  })}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => {
                    return vehicle.vehicleInfo.driveType === "AWD" ||
                      vehicle.vehicleInfo.driveType === "4x4" ? (
                      <FeatureRequirementIcon
                        requirementPresent={true}
                        key={vehicle._maeTrimId}
                      />
                    ) : (
                      <FeatureRequirementIcon
                        requirementPresent={false}
                        key={vehicle._maeTrimId}
                      />
                    );
                  })}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => (
                    <FeatureRequirementIcon
                      requirementPresent={true}
                      key={vehicle._maeTrimId}
                    />
                  ))}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => {
                    return vehicle.calculatedMDPFeatures?.headRoomFirstRow >
                      38 &&
                      vehicle.calculatedMDPFeatures?.legRoomFirstRow > 38 ? (
                      <FeatureRequirementIcon
                        requirementPresent={true}
                        key={vehicle._maeTrimId}
                      />
                    ) : (
                      <FeatureRequirementIcon
                        requirementPresent={false}
                        key={vehicle._maeTrimId}
                      />
                    );
                  })}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => {
                    return vehicle.calculatedMDPFeatures.engine.includes(
                      "Gasoline"
                    ) ||
                      vehicle.calculatedMDPFeatures.engine.includes(
                        "Diesel"
                      ) ? (
                      <FeatureRequirementIcon
                        requirementPresent={false}
                        key={vehicle._maeTrimId}
                      />
                    ) : (
                      <FeatureRequirementIcon
                        requirementPresent={true}
                        key={vehicle._maeTrimId}
                      />
                    );
                  })}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => {
                    return vehicle.calculatedMDPFeatures.cargo > 20 ||
                      vehicle.vehicleInfo.maeSegment === "SUV" ||
                      vehicle.vehicleInfo.maeCargoCategory === "L" ? (
                      <FeatureRequirementIcon
                        requirementPresent={true}
                        key={vehicle._maeTrimId}
                      />
                    ) : (
                      <FeatureRequirementIcon
                        requirementPresent={false}
                        key={vehicle._maeTrimId}
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <DividerForCarsComparison carsToCompareList={carsToCompareList} />

            {/* cc-dimensions for the car */}
            <div className="chart-container">
              <div className="label-group expanded_mobile_width">
                <div
                  className="chart-feature-inner-flex"
                  style={{ opacity: "0" }}
                >
                  <p className="label-text-yellow-heading">Dimensions</p>
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => (
                    <span
                      className="chart_value_text cc-dimensions"
                      key={vehicle._maeTrimId}
                    >
                      {formatToOneDecimal(vehicle.calculatedMDPFeatures.cargo) +
                        (vehicle.calculatedMDPFeatures.cargo ? " cu ft." : "")}
                    </span>
                  ))}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => (
                    <span
                      className="chart_value_text cc-dimensions"
                      key={vehicle._maeTrimId}
                    >
                      {vehicle.calculatedMDPFeatures.headRoomFirstRow +
                        (vehicle.calculatedMDPFeatures.headRoomFirstRow
                          ? " in"
                          : "")}
                    </span>
                  ))}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => (
                    <span
                      className="chart_value_text cc-dimensions"
                      key={vehicle._maeTrimId}
                    >
                      {vehicle.calculatedMDPFeatures.headRoomSecondRow +
                        (vehicle.calculatedMDPFeatures.headRoomSecondRow
                          ? " in"
                          : "")}
                    </span>
                  ))}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => (
                    <span
                      className="chart_value_text cc-dimensions"
                      key={vehicle._maeTrimId}
                    >
                      {vehicle.calculatedMDPFeatures.legRoomFirstRow +
                        (vehicle.calculatedMDPFeatures.legRoomFirstRow
                          ? " in"
                          : "")}
                    </span>
                  ))}
                </div>
                <div className="chart-feature-inner-flex">
                  {carsToCompareList.map((vehicle) => (
                    <span
                      className="chart_value_text cc-dimensions"
                      key={vehicle._maeTrimId}
                    >
                      {vehicle.calculatedMDPFeatures.legRoomSecondRow +
                        (vehicle.calculatedMDPFeatures.legRoomSecondRow
                          ? " in"
                          : "")}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Banner onClick={exclusiveCtaClicked} 
        text={DEALERSHIP_SOURCE ? DEALERSHIP_OFFER_TEXT : "Get Exclusive deals and offers on these vehicles from Mae's hand-picked and trusted dealers near you."}
        buttonText={DEALERSHIP_SOURCE ? DEALERSHIP_OFFER_BUTTON_TEXT :"Buy with mae"}
        heading={false}/>
      </div>
    </>
  );
}
