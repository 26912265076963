import "../../styles/formStyles/formStyles.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignupForm from "../../components/signupForm/SignupForm";
import DashboardAuthenticate from "../../components/dashboardAuthenticate/DashboardAuthenticate";
import { sendSignupEmail } from "../../services/userEvent";
import { useAuthContext } from "../../contexts/authContext";
import { updateComparisonChartInfo } from "../../services/comparisonchart";

const SignUp = () => {
  const { login, setTriggerUpdateAuthContext, matchmaker } = useAuthContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signUpStatus, setSignUpStatus] = useState("Create Your Account");
  const [accountSignUpError, setAccountSignUpError] = useState("");
  const navigate = useNavigate();

  const googleAuth = () => {
    window.open(process.env.REACT_APP_GOOGLE_AUTH_API_URL, "_self");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      firstName,
      lastName,
      email,
      password,
    };

    if (!firstName || !lastName || !email || !password) {
      setAccountSignUpError("empty");
      return;
    }
    const btnSpinner = document.getElementById("btn-spin-loader");
    btnSpinner.className = "show-spin-loader";

    try {
      const response = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        setSignUpStatus("Signup successful");
        console.log("Signup successful");
        sendSignupEmail(email);
        login();
        setTriggerUpdateAuthContext((prevCount) => prevCount + 1);

        const responseJSON = await response.json()
        console.log(responseJSON,"responseJSON")
        console.log(matchmaker,"matchmaker signup")
        if (matchmaker?.profileQuiz && (responseJSON.user.profile?.profileQuizMaeTrimIds || matchmaker?.profileQuiz?.length !== 0) &&
          matchmaker?.profileQuizMaeTrims?.length !== 0) {
            console.log(matchmaker?.profileQuizMaeTrims?.length !== 0);
            console.log("REDIRECTION TO COMPARE AFTER SIGNUP");
            navigate("/compare")
        } else {
            navigate("/matchmaker-quiz");
        }
        
      } else {
        const errorData = await response.json();
        if (errorData.error === "Email already exists")
          setAccountSignUpError("email");
        console.log("Signup failed:", errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }

    btnSpinner.className = "hide-spin-loader";
  };

  return (
    <div className="signup-login-page">
      <SignupForm
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        signUpStatus={signUpStatus}
        setSignUpStatus={setSignUpStatus}
        handleSubmit={handleSubmit}
        accountSignUpError={accountSignUpError}
        googleAuth={googleAuth}
      />
      <DashboardAuthenticate />
    </div>
  );
};

export default SignUp;
